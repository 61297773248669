import React, { useState, useRef, useEffect } from "react";
import { Slide } from "react-slideshow-image";
import VideoPlayer from "../../baseComponents/VideoPlayer";

import "./index.css";

const Gallery = (props) => {
  const { pageIndex } = props;
  let { galeria } = props.data,
    [autoplay, setAutoplay] = useState(true),
    [lastPage, setLastPage] = useState(5),
    [slideIndex, setSlideIndex] = useState(0),
    [isHoverScrollBtnRight, setIsHoverScrollBtnRight] = useState(""),
    [isHoverScrollBtnLeft, setIsHoverScrollBtnLeft] = useState(""),
    { lang } = props,
    slideRef = useRef();
  const onMouseEnterLeft = () => setIsHoverScrollBtnLeft("active");
  const onMouseLeaveLeft = () => setIsHoverScrollBtnLeft("");
  const onMouseEnterRight = () => setIsHoverScrollBtnRight('active')
  const onMouseLeaveRight = () => setIsHoverScrollBtnRight('')
  const onChangeSlide = (_, newIndex) => setSlideIndex(newIndex);

  useEffect(() => {
    if (lastPage !== pageIndex) {
      if (pageIndex === 0) return setAutoplay(true);
      setAutoplay(false);
      if (slideRef.current) slideRef.current.goTo(0);
    }
    // eslint-disable-next-line
  }, [pageIndex]);

  const renderSlide = (slide, i) => {
    if (slide.type === "video") {
      return (
        <VideoPlayer
          url={slide.url}
          isFirst={i === 0}
          isVisible={i === slideIndex && pageIndex === 5}
          onPlay={() => setAutoplay(false)}
          poster={slide.image}
        />
      );
    }

    return (
      <div
        key={i}
        className="slide-image content-image full-background-cover"
        style={{ background: `url(${slide.url})` }}
      >
        <div
          className="footpage"
          dangerouslySetInnerHTML={{
            __html: slide.description[lang],
          }}
        />
      </div>
    );
  };

  const onNext = () => {
    if (slideRef.current) slideRef.current.goNext();
    setAutoplay(true);
  };
  const onPrev = () => {
    if (slideRef.current) slideRef.current.goBack();
    setAutoplay(true);
  };

  const properties = {
    autoplay,
    duration: 5000,
    arrows: false,
    transitionDuration: 1,
    infinite: true,
  };

  let slides = [];

  for (let item of galeria.images) {
    let { image = "", video = "" } = item;
    if (video.trim().length)
      slides.push({ url: video, type: "video", ...item });
    else slides.push({ url: image, type: "image", ...item });
  }

  return (
    <div id="gallery" className="page gallery-mobile">
      <div className="left-side">
        <div className="block-right border-bottom"></div>
        <div className="title">
          <div>
            <h1>{lang === "es" ? "Galería" : "Gallery"}</h1>
          </div>
        </div>
        <div className="block-right border-top"></div>
      </div>

      <div className="main-content">
        <div className="full-background-cover lines border-bottom" />
        <div className="container">
          <Slide
            canSwipe={false}
            className="slider"
            ref={slideRef}
            onChange={onChangeSlide}
            {...properties}
          >
            {slides.map((slide, index) => renderSlide(slide, index))}
          </Slide>
        </div>
        <div className="full-background-cover border-top">
          <div className="slider-buttons">
            <div className="archive-button" onClick={onNext}>
              <span className="icon icon-chevron-right" />
            </div>
            <div className="archive-button" onClick={onPrev}>
              <span className="icon icon-chevron-left" />
            </div>
          </div>
        </div>
      </div>

      <div className="right-side">
        <div
          className="scroll-btn border-bottom"
          onClick={props.onNext}
          onMouseEnter={onMouseEnterRight}
          onMouseLeave={onMouseLeaveRight}
        >
          <span className='icon icon-big-arrow-right arrow-btn' />
          <span className={`hover ${isHoverScrollBtnRight}`}>
            {lang === 'es' ? 'Donar' : 'Donate'}
          </span>
        </div>
        <div className="title"></div>
        <div
          className="scroll-btn border-top back-btn"
          onClick={props.onPrev}
          onMouseEnter={onMouseEnterLeft}
          onMouseLeave={onMouseLeaveLeft}
        >
          <span className="icon icon-big-arrow-left arrow-btn" />
          <span className={`hover ${isHoverScrollBtnLeft}`}>
            {lang === "es" ? "Residentes" : "Residents"}
          </span>
        </div>
      </div>
    </div>
  );
};

export default Gallery;
