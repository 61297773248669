import React, { useState, useRef } from 'react'
import { Slide } from 'react-slideshow-image'
import { Helmet } from 'react-helmet'

import element02 from '../../assets/imgs/home/element_02.png'
import element01 from '../../assets/imgs/us/element_02.png'
import element03 from '../../assets/imgs/us/element_01.png'
import image from '../../assets/imgs/us/img.png'
import hoverBtn from '../../assets/imgs/home/hover_scroll.png'

import './index.css'


const Us = props => {
  let { equipo, nosotros } = props.data
    , { lang } = props

  const [isHoverScrollBtn, setIsHoverScrollBtn] = useState('')
    , [slideIndex, setSlideIndex] = useState(0)
    , slideRef = useRef()
  const onMouseEnter = () => setIsHoverScrollBtn('active')
  const onMouseLeave = () => setIsHoverScrollBtn('')
  const onChangeSlide = (oldIndex, newIndex) => setSlideIndex(newIndex)
  const goto = index => {
    setSlideIndex(index)
    slideRef.current.goTo(index)
  }

  const properties = {
    autoplay: false,
    arrows: false,
  }

  return <div id='us' className='page us-mobile'>
    <div className='gallery-block'>
      <div className='slider-block'>
        <Slide canSwipe={false} onChange={onChangeSlide} className='slider' ref={slideRef} {...properties}>
          {
            nosotros.images
              .filter((_, i) => i === 0)
              .map((slide, index) => <div key={index} className='slide-image' style={{ background: `url(${slide.image})` }} />)
          }
        </Slide>
        <div className='slider-buttons'>
          {
            /*  nosotros.images.map((_, index) => {
               let isActive = index === slideIndex ? 'active' : ''
               return <span key={index} className={`slide-btn ${isActive}`} onClick={() => goto(index)}>{index + 1}</span>
             }) */
          }
        </div>
      </div>
      <div className='single-image-block'>
        <div style={{ background: `url(${nosotros.image})` }} className='image' />
      </div>
    </div>
    <div className='info-block'>
      <div className='info-header'>
        <div className='info-title'>
          <h1>{nosotros.detail.title[lang]}</h1>
        </div>
        <div className='info-image btn' onClick={props.onNext} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
          <span className='icon icon-big-arrow-right arrow-btn' />
          <span className={`hover ${isHoverScrollBtn}`}>
            {lang === 'es' ? 'Programas' : 'Programs'}
          </span>
        </div>
      </div>
      <div className='info-description'>
        <div className='info-description-text'>
          <div className='container'>
            <div className='text' dangerouslySetInnerHTML={{ __html: nosotros.detail.content[lang] }}>
            </div>
            <div className='founders-container'>
              <div className='image' style={{ background: `url(${element03})` }}>
              </div>
              <div className='founders'>
                {
                  equipo.map((founder, index) => <div key={index} className='founder'>
                    <h3>{founder.name}</h3>
                    <span>{founder.job[lang]}</span>
                  </div>)
                }
              </div>
            </div>
          </div>
          <div className='single-image-block'>
            <div style={{ background: `url(${image})` }} className='image' />
          </div>

          <div className='bottom-arrow image-bottom'>
            <img src={element01} alt='lineas' />
            <img src={element02} alt='flecha' className='arrow' onClick={props.onNext} />

          </div>
        </div>
        <div className='info-image info-image-desc'>
          <div className='info-image bottom-arrow btn back-btn' onClick={props.onPrev} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
            <span className='icon icon-big-arrow-left arrow-btn' />
            <span className={`hover ${isHoverScrollBtn}`}>
              {lang === 'es' ? 'Inicio' : 'Home'}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
}

export default Us

/*

                <div className='founder'>
                  <h3>Manuel Sentíes</h3>
                  <span>Co-fundador y director operativo</span>
                </div>
                <div className='founder'>
                  <h3>Ana Elena Reyes Retana</h3>
                  <span>Coordinadora staff</span>
                </div>
                <div className='founder'>
                  <h3>Antonio De La Rosa</h3>
                  <span>Encargado de residencia</span>
                </div>
                <div className='founder'>
                  <h3>Carmen Sánchez</h3>
                  <span>Staff Cocina</span>
                </div>
                <div className='founder'>
                  <h3>Bernardino Asencio </h3>
                  <span>Staff</span>
                </div>
                <div className='founder'>
                  <h3>Alejandro Zárate</h3>
                  <span>Encargado Rancho Santa Ana</span>
                </div>
*/