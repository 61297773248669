import React, { useState, useRef } from 'react'
import { Fade } from 'react-slideshow-image'

import pin from '../../assets/imgs/location/comollegar_icon.png'
import line from '../../assets/imgs/home/element_02.png'
import hoverBtn from '../../assets/imgs/home/hover_scroll.png'
import './index.css'

const Location = props => {
  let { ubicacion } = props.data
    , [showMap, setShowMap] = useState(false)
    , [isHoverScrollBtnRight, setIsHoverScrollBtnRight] = useState('')
    , [isHoverScrollBtnLeft, setIsHoverScrollBtnLeft] = useState('')
    , { lang } = props
  const onMouseEnter = () => { setShowMap(true) }
    , onMouseLeave = () => { setShowMap(false) }
  const onMouseEnterLeft = () => setIsHoverScrollBtnLeft('active')
  const onMouseLeaveLeft = () => setIsHoverScrollBtnLeft('')
  const onMouseEnterRight = () => setIsHoverScrollBtnRight('active')
  const onMouseLeaveRight = () => setIsHoverScrollBtnRight('')
  const onClickMap = () => {
    let ua = navigator.userAgent.toLowerCase()
      , isAndroid = ua.indexOf("android") > -1
      , { latitude, longitude } = ubicacion.coord

    if (isAndroid) window.location = `geo:${latitude},${longitude}`
    else window.location = `http://maps.apple.com/?ll=${latitude},${longitude}`
  }

  const properties = {
    autoplay: true,
    duration: 3000,
    arrows: false,
  }

  return <div id='location' className='page location-mobile'>
    <div className='left-side'>
      <div className='block-right border-bottom'>
      </div>
      <div className='title'>
        <div>
          <h1>{lang === 'es' ? 'Ubicación' : 'Location'}</h1>
        </div>
      </div>
      <div className='block-right border-top'>
      </div>
    </div>

    <div className='main-content'>
      <div className='full-background-cover lines border-bottom' />
      <div className='container'>
        <div className='content-image full-background-cover' style={{ background: `url(${ubicacion.image})` }} >
          <div className='slider-fade'>
            <Fade  {...properties}>
              {
                ubicacion.images.map((slide, index) => <div key={index} className='slide-image' style={{ background: `url(${slide.image})` }} >
                </div>)
              }
            </Fade>
          </div>
        </div>
        <div className='container-maps'>
          <div className='container-item map-item border-bottom'>
            {
              showMap
                ? <iframe onMouseLeave={onMouseLeave}
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d30002.815997248654!2d-99.55267154542464!3d19.951693691850647!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d23427fb81cf91%3A0x912412130a9a103c!2sJilotepec%20de%20Molina%20Enr%C3%ADquez%2C%20State%20of%20Mexico!5e0!3m2!1sen!2smx!4v1612305642769!5m2!1sen!2smx"
                  frameBorder="0"
                  allowFullScreen="" aria-hidden="false" tabIndex="0"></iframe>
                : <div className='pin-container' onMouseEnter={onMouseEnter}>
                  <img src={pin} alt='mapa' />
                  <div className='orange-btn'>
                    <p>{lang === 'es' ? 'cómo' : ''}</p>
                    <p>{lang === 'es' ? 'llegar' : 'map'}</p>
                  </div>
                </div>
            }
            <div className='content-image full-background-cover content-image-mobile' style={{ background: `url(${ubicacion.image})` }}>
              <Fade  {...properties}>
                {
                  ubicacion.images.map((slide, index) => <div key={index} className='slide-image' style={{ background: `url(${slide.image})` }}>
                    <img src={slide.image} alt='image' />
                  </div>)
                }
              </Fade>
              <div className='grid'>
                <div className='container-item item1' />
                <div className='container-item item2' />
                <div className='container-item item3' />
                <div className='container-item' />
              </div>
            </div>
          </div>
          <div className='container-item border-left border-bottom background'>
            <div dangerouslySetInnerHTML={{ __html: ubicacion.detail.content[lang] }} />
          </div>
          <div className='container-item' />
          <div className='container-item border-left' />
        </div>
      </div>
      <div className='full-background-cover lines border-top' />
    </div>

    <div className='right-side'>
      <div className='scroll-btn border-bottom' onClick={props.onNext} onMouseEnter={onMouseEnterRight} onMouseLeave={onMouseLeaveRight}>
        <span className='icon icon-big-arrow-right arrow-btn' />
        <span className={`hover ${isHoverScrollBtnRight}`}>
          {lang === 'es' ? 'Residentes' : 'Residents'}
        </span>
      </div>
      <div className='title'>
        <img src={pin} alt='mapa' onClick={onClickMap} />
      </div>
      <div className='scroll-btn border-top back-btn' onClick={props.onPrev} onMouseEnter={onMouseEnterLeft} onMouseLeave={onMouseLeaveLeft}>
        <span className='icon icon-big-arrow-left arrow-btn' />
        <span className={`hover ${isHoverScrollBtnLeft}`}>
          {lang === 'es' ? 'Programas' : 'Programs'}
        </span>
      </div>
    </div>
  </div>
}

export default Location