import React, { useState } from "react";
import SubInfo from "./SubInfo";

import image from "../../../assets/imgs/home/img.png";
import lines from "../../../assets/imgs/us/element_02.png";

const SecondaryInfo = (props) => {
  let { currentProgram, program, lang } = props;
  const [isHoverScrollBtn, setIsHoverScrollBtn] = useState("");
  const onMouseEnter = () => setIsHoverScrollBtn("active");
  const onMouseLeave = () => setIsHoverScrollBtn("");

  if (!program) return <div id="secondary-info"></div>;

  let title =
    program.detail && program.detail.title
      ? program.detail.title
      : { es: "", en: "" };

  let isScholarship = typeof title["en"] === "string" ? title["en"] : "";
  isScholarship =
    isScholarship.toUpperCase().search("COBERTIZO GRANT") !== -1 ||
    isScholarship.search(/\bNacional\b/) !== -1 ||
    isScholarship.search(/\bNational\b/) !== -1;

  return (
    <div id="secondary-info">
      {currentProgram !== "residence" ? (
        <div className="periods">
          <div className="program-subinfo-content">
            <SubInfo
              lang={lang}
              {...program}
              onApply={props.onApply}
              isScholarship={isScholarship}
            />
          </div>
          <div className="scroll-section">
            <div
              className="scroll-btn scroll-btn-tall"
              onClick={props.onNext}
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
            >
              <span className="icon icon-big-arrow-right arrow-btn" />
              <span className={`hover ${isHoverScrollBtn}`}>
                {lang === "es" ? "Ubicación" : "Location"}
              </span>
            </div>
            <div
              className="scroll-btn scroll-btn-small"
              onClick={props.onPrev}
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
            >
              <span className="icon icon-big-arrow-left arrow-btn" />
              <span className={`hover ${isHoverScrollBtn}`}>
                {lang === "es" ? "Nosotros" : "Us"}
              </span>
            </div>
          </div>
        </div>
      ) : (
        <React.Fragment>
          <div className="full-background-contain image-container main">
            <div
              className="full-background-cover image-container circled"
              style={{ background: `url("${image}")` }}
            />
            <div className="full-background-sidebar">
              <div
                className="scroll-btn"
                onClick={props.onPrev}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
              >
                <span className="icon icon-big-arrow-left arrow-btn" />
                <span className={`hover ${isHoverScrollBtn}`}>
                  {lang === "es" ? "Nosotros" : "Us"}
                </span>
              </div>
            </div>
          </div>
          <div className="scroll-buttons">
            <img src={lines} alt="lineas" className="image" />
            <div
              className="scroll-btn"
              onClick={props.onNext}
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
            >
              <span className="icon icon-big-arrow-right arrow-btn" />
              <span className={`hover ${isHoverScrollBtn}`}>
                {lang === "es" ? "Ubicación" : "Location"}
              </span>
            </div>
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default SecondaryInfo;

// style={{ background: `url("${planta}")` }}
