import React, { useState } from "react";

import logo from "../../assets/imgs/logo.png";

import "./index.css";

const Header = (props) => {
  const [submenu, setSubmenu] = useState(""),
    { lang, setLang, activeIndex } = props;

  const onOpenNav = () => {
    let menu = document.getElementById("side-menu");
    if (menu) {
      let isActive = menu.getAttribute("class");
      isActive
        ? menu.removeAttribute("class")
        : menu.setAttribute("class", "active");
    }
  };

  const onOpenSidebar = () => {
    let menu = document.getElementById("sidebar-menu");
    if (menu) {
      let isActive = menu.getAttribute("class");
      isActive
        ? menu.removeAttribute("class")
        : menu.setAttribute("class", "active");
    }
  };

  const onScroll = (index) => {
    let sections = [
        "home-mobile",
        "us-mobile",
        "programs-mobile",
        "location-mobile",
        "archive-mobile",
        "gallery-mobile",
        "donate-mobile",
        "allies-mobile"
      ],
      height = 0;
    height = sections
      .filter((_, i) => i < index)
      .map((key) => document.querySelector(`#mobile .${key}`).clientHeight)
      .reduce((a, b) => a + b);
    window.scrollTo(
      0,
      height - document.getElementById("header_container").clientHeight
    );
    onOpenNav();
  };

  return (
    <React.Fragment>
      <div id="header_container" className="header-container">
        <div className="triangle triangle-bottom-right" />
        <div className="header">
          <div
            className={`submenu ${submenu}`}
            onMouseLeave={() => setSubmenu("")}
          >
            {/* <img className="logo" src={logo} alt="cobertizo" /> */}
            {Object.values(props.programs).map((program, index) => {
              let title =
                program.detail && program.detail.title
                  ? program.detail.title
                  : { es: "", en: "" };
              title = title[lang];
              return (
                <span
                  key={index}
                  onClick={() => {
                    props.onChangePage(2);
                    props.onChangeProgram(program.id);
                    setSubmenu("");
                  }}
                  dangerouslySetInnerHTML={{ __html: title }}
                ></span>
              );
            })}
          </div>
          <div className="header-contet">
            <img
              className="logo"
              src={logo}
              alt="cobertizo"
              onClick={() => {
                props.onChangePage(0);
                // Needed for mobile, to go back to top on click
                document.body.scrollTop = 0; 
                document.documentElement.scrollTop = 0;
                let menu = document.getElementById("side-menu");
                if (menu) {
                  menu.removeAttribute("class")
                }
              }}
            />
            <nav>
              <span
                className={`header-item ${activeIndex === 1 ? "active" : ""}`}
                onClick={() => props.onChangePage(1)}
                onMouseEnter={() => setSubmenu("")}
              >
                <span className="icon icon-star-1" />
                <span>{lang === "es" ? "nosotros" : "about us"}</span>
              </span>
              <span
                className={`header-item ${activeIndex === 2 ? "active" : ""}`}
                // onClick={() => props.onChangePage(2)}
                onClick={() => {
                  props.onChangeProgram(undefined);
                  props.onChangePage(2);
                  setSubmenu("");
                }}
                onMouseEnter={() => setSubmenu("")}
              >
                <span className="icon icon-half-circle" />
                <span onMouseEnter={() => setSubmenu("active")}>
                  {lang === "es" ? "residencia" : "residency"}
                </span>
              </span>
              <span
                className={`header-item ${activeIndex === 3 ? "active" : ""}`}
                onClick={() => props.onChangePage(3)}
                onMouseEnter={() => setSubmenu("")}
              >
                <span className="icon icon-romb" />
                <span>{lang === "es" ? "ubicación" : "location"}</span>
              </span>
              <span
                className={`header-item ${activeIndex === 4 ? "active" : ""}`}
                onClick={() => props.onChangePage(4)}
                onMouseEnter={() => setSubmenu("")}
              >
                <span className="icon icon-star-2" />
                <span>{lang === "es" ? "residentes" : "residents"}</span>
              </span>
              <span
                className={`header-item ${activeIndex === 5 ? "active" : ""}`}
                onClick={() => props.onChangePage(5)}
                onMouseEnter={() => setSubmenu("")}
              >
                <span className="icon icon-star-1" />
                <span>{lang === "es" ? "galería" : "gallery"}</span>
              </span>
              <span
                className={`header-item ${activeIndex === 6 ? "active" : ""}`}
                onClick={() => props.onChangePage(6)}
                onMouseEnter={() => setSubmenu("")}
              >
                <span className="icon icon-romb" />
                <span>{lang === "es" ? "donar" : "donate"}</span>
              </span>
              <span
                className={`header-item ${activeIndex === 7 ? "active" : ""}`}
                onClick={() => props.onChangePage(7)}
                onMouseEnter={() => setSubmenu("")}
              >
                <span className="icon icon-half-circle" />
                <span>{lang === "es" ? "aliadxs" : "allies"}</span>
              </span>
            </nav>
          </div>

          <div className="menu-icon icon icon-burger-1 mobile-nav-icon" onClick={onOpenNav} />
          <div className="icon-burger-1 sidebar-nav-icon" onClick={onOpenSidebar} />

          <div className="header-social-networks">
            <span
              className="header-item"
              onClick={() => setLang(lang === "es" ? "en" : "es")}
            >
              <span>{lang === "es" ? "english" : "español"}</span>
            </span>
            <a href="mailto:info@cobertizo.com.mx">
              <span className="icon icon-envelope" />
            </a>
            <a
              href="https://m.facebook.com/profile.php?id=112005503610389&ref=content_filter"
              target="_blank"
              rel="noreferrer"
            >
              <span className="icon icon-facebook" />
            </a>
            <a
              href="https://www.instagram.com/cobertizomx/"
              target="_blank"
              rel="noreferrer"
            >
              <span className="icon icon-instagram" />
            </a>
          </div>
        </div>
        <div className="triangle triangle-top-left" />
      </div>
      <div id="side-menu">
        {/* <div className="close-label" onClick={onOpenNav}>
          <span className="icon icon-times"></span>
        </div> */}
        <div className="options">
          <div className="link" onClick={() => onScroll(1)}>
            <span>{lang === "es" ? "nosotros" : "about us"}</span>
          </div>
          <div
            className="link"
            onClick={() => {
              props.onChangeProgram(undefined);
              onScroll(2);
              setSubmenu("");
            }}
          >
            <span onMouseEnter={() => setSubmenu("active")}>
              {lang === "es" ? "residencia" : "residency"}
            </span>
          </div>
          <div className="link submenu">
            {Object.values(props.programs).map((program, index) => {
              let title =
                program.detail && program.detail.title
                  ? program.detail.title
                  : { es: "", en: "" };

              title = title[lang];

              return (
                <span
                  key={index}
                  onClick={() => {
                    props.onChangeProgram(program.id);
                    setSubmenu("");
                    onScroll(2);
                  }}
                  dangerouslySetInnerHTML={{ __html: title }}
                ></span>
              );
            })}
          </div>
          <div className="link" onClick={() => onScroll(3)}>
            <span>{lang === "es" ? "ubicación" : "location"}</span>
          </div>
          <div className="link" onClick={() => onScroll(4)}>
            <span>{lang === "es" ? "residentes" : "residents"}</span>
          </div>
          <div className="link" onClick={() => onScroll(5)}>
            <span>{lang === "es" ? "galería" : "gallery"}</span>
          </div>
          <div className="link" onClick={() => onScroll(6)}>
            <span>{lang === "es" ? "donar" : "donate"}</span>
          </div>
          <div className="link" onClick={() => onScroll(7)}>
            <span>{lang === "es" ? "aliadxs" : "allies"}</span>
          </div>
          <div className="divider" />
          <div
            onClick={() => setLang(lang === "es" ? "en" : "es")}
            className={`logout link`}
          >
            <span>{lang === "es" ? "english" : "español"}</span>
          </div>
          <div className="header-social-networks">
            <a className="link" href="mailto:info@cobertizo.com.mx">
              <span className="icon icon-envelope" />
            </a>
            <a
              className="link"
              href="https://m.facebook.com/profile.php?id=112005503610389&ref=content_filter"
              target="_blank"
              rel="noreferrer"
            >
              <span className="icon icon-facebook" />
            </a>
            <a
              className="link"
              href="https://www.instagram.com/cobertizomx/"
              target="_blank"
              rel="noreferrer"
            >
              <span className="icon icon-instagram" />
            </a>
          </div>
        </div>
      </div>
      <div id="sidebar-menu">
        <div className="close-label" onClick={onOpenSidebar}>
          <span className="icon icon-times"></span>
        </div>
        <div className="options">
          <div className="link" onClick={() => {
            props.onChangePage(1);
            onOpenSidebar();
            }}>
            <span>{lang === "es" ? "nosotros" : "about us"}</span>
          </div>
          <div
            className="link"
            onClick={() => {
              props.onChangeProgram(undefined);
              props.onChangePage(2);
              setSubmenu("");
              onOpenSidebar();
            }}
          >
            <span onMouseEnter={() => setSubmenu("active")}>
              {lang === "es" ? "residencia" : "residency"}
            </span>
          </div>
          <div className="link submenu">
            {Object.values(props.programs).map((program, index) => {
              let title =
                program.detail && program.detail.title
                  ? program.detail.title
                  : { es: "", en: "" };

              title = title[lang];

              return (
                <span
                  key={index}
                  onClick={() => {
                    props.onChangeProgram(program.id);
                    setSubmenu("");
                    props.onChangePage(2);
                    onOpenSidebar();
                  }}
                  dangerouslySetInnerHTML={{ __html: title }}
                ></span>
              );
            })}
          </div>
          <div className="link" onClick={() => {
            props.onChangePage(3);
            onOpenSidebar();
            }}>
            <span>{lang === "es" ? "ubicación" : "location"}</span>
          </div>
          <div className="link" onClick={() => {
            props.onChangePage(4);
            onOpenSidebar();
            }}>
            <span>{lang === "es" ? "residentes" : "residents"}</span>
          </div>
          <div className="link" onClick={() => {
            props.onChangePage(5);
            onOpenSidebar();
            }}>
            <span>{lang === "es" ? "galería" : "gallery"}</span>
          </div>
          <div className="link" onClick={() => {
            props.onChangePage(6);
            onOpenSidebar();
            }}>
            <span>{lang === "es" ? "donar" : "donate"}</span>
          </div>
          <div className="link" onClick={() => {
            props.onChangePage(7);
            onOpenSidebar();
            }}>
            <span>{lang === "es" ? "aliadxs" : "allies"}</span>
          </div>
          <div className="divider" />
          <div
            onClick={() => {
              setLang(lang === "es" ? "en" : "es");
              onOpenSidebar();
            }}
            className={`logout link`}
          >
            <span>{lang === "es" ? "english" : "español"}</span>
          </div>
          <div className="header-social-networks">
            <a className="link" href="mailto:info@cobertizo.com.mx">
              <span className="icon icon-envelope" />
            </a>
            <a
              className="link"
              href="https://m.facebook.com/profile.php?id=112005503610389&ref=content_filter"
              target="_blank"
              rel="noreferrer"
            >
              <span className="icon icon-facebook" />
            </a>
            <a
              className="link"
              href="https://www.instagram.com/cobertizomx/"
              target="_blank"
              rel="noreferrer"
            >
              <span className="icon icon-instagram" />
            </a>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Header;