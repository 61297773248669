import { useState } from 'react'
import diamond from "../../assets/imgs/us/element_01.png";
import './index.css'


const Donate = props => {
  let { lang } = props

  const [isHoverScrollBtnRight, setIsHoverScrollBtnRight] = useState("");
  const [isHoverScrollBtnLeft, setIsHoverScrollBtnLeft] = useState("");
  const onMouseEnterLeft = () => setIsHoverScrollBtnLeft("active");
  const onMouseLeaveLeft = () => setIsHoverScrollBtnLeft("");
  const onMouseEnterRight = () => setIsHoverScrollBtnRight('active')
  const onMouseLeaveRight = () => setIsHoverScrollBtnRight('')
  return (
    <div id="donate" className="page donate-mobile">
      <div id="left-content">
        <div className="title">
          <span>
            {lang === "es" ? "Donar" : "Donate"}
          </span>
        </div>
        <div className="description">
          <p>
            {lang === "es"
              ? "Cobertizo es una asociación civil sin fines de lucro cuyo principal objetivo es promover la producción y reflexión de arte contemporáneo en Latinoamérica, para lo cual las aportaciones de cualquier índole nos son fundamentales, ya que nos permiten seguir construyendo un proyecto cada vez más plural e inclusivo. En este sentido y con la intención de ampliar el espectro de apoyos a Cobertizo, abrimos la opción de aportaciones directas por parte de personas interesadas en la subsistencia del proyecto, mismas que pueden ser de la cantidad que se desee. Cualquier aportación que se haga nos es de gran ayuda. ¡Gracias!"
              : "Cobertizo is a non profit asossiation whose main goal is to promote art production from a critical thinking in Latin America, for which contributions of any kind are key in order to carry on building a more plural and inclusive project. In this sense and intending to widen possibilities for contributing to Cobertizo, we render the option for direct contributions from whoever is interested in the project, which can be of any amount chosen. Any donation means great help for us. ¡Thank you!"
            }
          </p>
        </div>
        <div className="description-image">
          <img src={diamond} alt="diamante" className="image" />
        </div>
      </div>
      <div id="right-content">
        <div className="form-content">
        <script src="https://donorbox.org/widget.js" paypalExpress="false"></script>
        <iframe src={`https://donorbox.org/embed/cobertizo-donaciones?language=${lang}`} title="donorbox" 
        allowpaymentrequest="allowpaymentrequest" seamless="seamless" 
        allow="payment" height="100%" width="100%"></iframe>
        </div>
        <div className="scroll-section">
          <div
            className="scroll-btn border-bottom"
            onClick={props.onNext}
            onMouseEnter={onMouseEnterRight}
            onMouseLeave={onMouseLeaveRight}
          >
            <span className='icon icon-big-arrow-right arrow-btn' />
            <span className={`hover ${isHoverScrollBtnRight}`}>
              {lang === 'es' ? 'Aliados' : 'Allies'}
            </span>
          </div>
          <div
            className="scroll-btn back-btn"
            onClick={props.onPrev}
            onMouseEnter={onMouseEnterLeft}
            onMouseLeave={onMouseLeaveLeft}
          >
            <span className="icon icon-big-arrow-left arrow-btn" />
            <span className={`hover ${isHoverScrollBtnLeft}`}>
              {lang === "es" ? "Galería" : "Gallery"}
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Donate