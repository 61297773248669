import React, { useState } from 'react'

import './index.css'


const Allies = props => {
  let { lang } = props

  const patronato = ['⁠Mercedes Saenz & Alejandra Ríos', 
    '⁠Ismael Reyes Retana',
    '⁠Colección Zarur',
    '⁠Julio Salazar',
    '⁠Marcelo Vallecillos & Georgia Durán',
    ];

  const aliados = [
    {
      title: 'laNao galería, Proyectos Monclova, N.A.S.A.L., Llano, Saenger Galería, Daniela Elbahara Galería, Guadalajara 90210',
      description: {
        en: 'Inaugural residency for new studios',
        es: 'Residencia inaugural de nuevos estudios',
      },
      year: 2022,
    },
    {
      title: 'MACROSALÓN 2022',
      description: {
        en: 'Art fair price',
        es: 'Premio de feria de arte',
      },
      year: 2022,
    },
    {
      title: 'AYARKUT',
      description: {
        en: 'Fully subsidized residency for Latin American artists',
        es: 'Residencia completamente subsidiada para artistas latinoamericanxs',
      },
      year: 2023,
    },
    {
      title: 'Patronato Cobertizo',
      description: {
        en: 'First residence of the Board Cobertizo (by invitation)',
        es: 'Primera residencia del Patronato Cobertizo (por invitación)',
      },
      year: 2023,
    },
    {
      title: 'Salón ACME 11',
      description: {
        en: 'Award Salón ACME 11 for two artists',
        es: '⁠Premio Salón ACME 11 para dos artistas',
      },
      year: 2024,
    },
    {
      title: 'Beca Randy Walz',
      description: {
        en: 'Agreement with the Randy Walz Scholarship for a Bajio artist',
        es: 'Convenio con la Beca Randy Walz para un artista del Bajío',
      },
      year: 2024,
    },
    {
      title: 'Foco lab.',
      description: {
        en: 'Collaboration for print collection printing',
        es: 'Colaboración para impresión de colección de prints',
      },
      year: 2024,
    },
  ];

  const getAlliesColumnByYear = (year) => {
    const columnClass = year % 10 % 2 !== 0 ? 'allies-column odd' : 'allies-column';
    return (
      <span className={columnClass}>
        <div className='allies-title'>
          <span className='icon icon-star-3' />
          <h2>{year}</h2>
          <span className='icon icon-star-3' />
        </div>
        {aliados.filter((ally) => ally.year === year).map((ally) =>
          <div key={ally.title} className='ally'>
            <h4>{ally.title}</h4>
            <p>{ally.description[lang]}</p>
          </div>
        )}
      </span>
    )
  }


  const [isHoverScrollBtn, setIsHoverScrollBtn] = useState('')
  const onMouseEnter = () => setIsHoverScrollBtn('active')
  const onMouseLeave = () => setIsHoverScrollBtn('')

  return <div id='allies' className='page allies-mobile'>
    <div className='info-block'>
      <div className='info-header-container'>
        <div className='info-title'>
          {/* <h1>{aliados.detail.title[lang]}</h1> */}
          <h1>{lang === 'es' ? 'Aliadxs' : 'Allies'}</h1>
        </div>
        <div className='info-description'>
          {/* <div className='text' dangerouslySetInnerHTML={{ __html: aliados.detail.content[lang] }}> */}
          {/* </div> */}
          <p className='text'>
            {lang === 'es' ? 'Desde el inicio, Cobertizo hace un esfuerzo constante por generar colaboraciones con otras instancias que nos ayuden a lograr nuestros objetivos. Hasta ahora hemos logrado los siguientes convenios y colaboraciones:' : 'From the beginning, Cobertizo has made a constant effort to generate collaborations with other entities that help us achieve our objectives. Up to now we have achieved the following agreements and collaborations:'}
          </p>
        </div>
      </div>
      <div className='patronato'>
        <h2>{lang === 'es' ? 'Patronato' : 'Board'}</h2>
        <span className='patronos-container'>
          {patronato.map((patrono, index) =>
            <div className='patrono'>
              <h4 key={index}>{patrono}</h4>
            </div>
          )}
        </span>
      </div>
      <div className='allies-container'>
        {getAlliesColumnByYear(2024)}
        {getAlliesColumnByYear(2023)}
        {getAlliesColumnByYear(2022)}
      </div>
    </div>
    <div className='info-arrows'>
      <div 
        className='scroll-btn back-btn' 
        onClick={props.onPrev} 
        onMouseEnter={onMouseEnter} 
        onMouseLeave={onMouseLeave}
      >
        <span className='icon icon-big-arrow-left arrow-btn' />
        <span className={`hover ${isHoverScrollBtn}`}>
          {lang === 'es' ? 'Donar' : 'Donate'}
        </span>
      </div>
    </div>
  </div>
}

export default Allies