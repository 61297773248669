import React, { useState, useRef, useEffect } from 'react'
import AliceCarousel from 'react-alice-carousel'
import Slider from 'react-slick';
import Artist from './components/Artist'

import line from '../../assets/imgs/home/element_02.png'
import hoverBtn from '../../assets/imgs/home/hover_scroll.png'

import './index.css'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

let currentSlide = 0

const Archive = props => {
  let { artistas } = props.data
    , { lang, activeIndex } = props
    , slideOneRef = useRef(null)
    , slideTwoRef = useRef(null)
    , [currentArtist, setCurrentArtist] = useState(null)
    , [currentSlide, setCurrentSlide] = useState(0)

  const onSlideChange = (e) => {
    setCurrentSlide(e)
  }

  useEffect(() => {
    if ((activeIndex!==4) && currentArtist) 
      setCurrentArtist(null)
  }, [activeIndex])

  artistas = Object.values(artistas)
    .map((artista, index) => {
      let image = Object.values(artista.imagenes)[0]
        , isEven = index % 2 === 0 ? 'even-item' : ''

      return <div className={`archive-item ${isEven}`} key={index} onClick={() => setCurrentArtist(artista)}>
        <div className='full-background-cover' style={{ background: `url(${image})` }} />
        <div className='layer'>
          <p>{artista.title}</p>
        </div>
      </div>
    })

  let pivot = Math.floor(artistas.length / 2)
  let firstArray = artistas.slice(0, pivot)
    , secondArray = artistas.slice(pivot + 1)

  const onNext = () => {
    if (slideOneRef.current) slideOneRef.current.slickNext()
    if (slideTwoRef.current) slideTwoRef.current.slickNext()
  }
  const onPrev = () => {
    if (slideOneRef.current) slideOneRef.current.slickPrev()
    if (slideTwoRef.current) slideTwoRef.current.slickPrev()
  }


  if (currentArtist) return <Artist
    lang={lang}
    data={currentArtist}
    goBack={() => {
      setCurrentArtist(null)
    }}
    onLockScroll={props.onLockScroll}
    onUnlockScroll={props.onUnlockScroll}
  />

  let settings = {
    dots: false,
    infinite: true,
    speed: 2000,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    swipe: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
    ]
  }

  return <div id='archive' className='page archive-mobile'>
    <div className='main-content'>
      <div className='archive-slider'>
        <Slider {...settings} ref={slideOneRef} afterChange={onSlideChange} initialSlide={currentSlide} >
          {firstArray}
        </Slider>
      </div>
      <div className='archive-control'>
        <div className='slider-buttons'>
          <div className='archive-button' onClick={onPrev}>
            <span className='icon icon-chevron-left' />
          </div>
        </div>
        <div className='archive-title'>
          <span className='icon icon-star-3' />
          <h1>{lang === 'es' ? 'Residentes' : 'Residents'}</h1>
          <span className='icon icon-star-3' />
        </div>
        <div className='slider-buttons'>
          <div className='archive-button' onClick={onNext}>
            <span className='icon icon-chevron-right' />
          </div>

        </div>
      </div>
      <div className='archive-slider'>
        <Slider {...settings} ref={slideTwoRef} initialSlide={currentSlide}>
          {secondArray}
        </Slider>
      </div>
    </div>
    <div className='scroll-section'>
      <div className='scroll-btn border-bottom' onClick={props.onNext}>
        <span className='icon icon-big-arrow-right arrow-btn' />
        <span className={`hover`}>
          {lang === 'es' ? 'Galería' : 'Gallery'}
        </span>
      </div>
      <div className='scroll-btn border-top' onClick={props.onPrev}>
        <span className='icon icon-big-arrow-left arrow-btn' />
        <span className={`hover`}>
          {lang === 'es' ? 'Ubicación' : 'Location'}
        </span>
      </div>
    </div>
  </div>
}

export default Archive